const gameProviders = {
  'all': {
    name: 'All',
    sections: []
  },
  '_netent': {
    name: 'NetEnt',
    sections: [
      'default'
    ]
  },
  '_gameart': {
    name: 'Gameart',
    sections: [
      'default'
    ]
  },
  '_microgaming': {
    name: 'Microgaming',
    sections: [
      'default'
    ]
  },
  '_pragmatic_play': {
    name: 'PragmaticPlay Drops&Wins',
    sections: [
      'default'
    ]
  },
  '_pragmaticplay': {
    name: 'PragmaticPlay',
    sections: [
      'default'
    ]
  },
  '_playtech': {
    name: 'Playtech',
    sections: [
      'default'
    ]
  },
  '_pariplay': {
    name: 'Pariplay',
    sections: [
      'default'
    ]
  },
  '_foxium': {
    name: 'Foxium',
    sections: [
      'default'
    ]
  },
  '_jftw': {
    name: 'JFTW',
    sections: [
      'default'
    ]
  },
  '_rabcat_gambling': {
    name: 'Rabcat',
    sections: [
      'default'
    ]
  },
  '_elk': {
    name: 'Elk',
    sections: [
      'default'
    ]
  },
  '_spinomenal': {
    name: 'Spinomenal',
    sections: [
      'default'
    ]
  },
  '_iron_dog': {
    name: 'Iron Dog',
    sections: [
      'default'
    ]
  },
  '_push_gaming': {
    name: 'Push gaming',
    sections: [
      'default'
    ]
  },
  '_relax_gaming': {
    name: 'Relax gaming',
    sections: [
    ]
  },
  '_felt_gaming': {
    name: 'Felt gaming',
    sections: [
      'default'
    ]
  },
  '_yggdrasil': {
    name: 'Yggdrasil',
    sections: [
      'default'
    ]
  },
  '_one_touch': {
    name: 'OneTouch',
    sections: [
      'default'
    ]
  },
  '_betixon': {
    name: 'Betixon',
    sections: [
      'default'
    ]
  },
  '_big_time_gaming': {
    name: 'Big Time gaming',
    sections: [
      'default'
    ]
  },
  '_thunderkick': {
    name: 'Thunderkick',
    sections: [
      'default'
    ]
  },
  '_amatic': {
    name: 'Amatic',
    sections: [
      'default'
    ]
  },
  '_hacksaw': {
    name: 'Hacksaw',
    sections: [
      'default'
    ]
  },
  '_blueprint': {
    name: 'Blueprint',
    sections: [
      'default'
    ]
  },
  '_slotmotion': {
    name: 'Slotmotion',
    sections: [
      'default'
    ]
  },
  '_caleta': {
    name: 'Caleta',
    sections: [
      'default'
    ]
  },
  '_indi_slots': {
    name: 'Indi slots',
    sections: []
  },
  '_xplosive_pro': {
    name: 'Xplosive Pro',
    sections: [
      'default'
    ]
  },
  '_kalamba': {
    name: 'Kalamba',
    sections: [
      'default'
    ]
  },
  '_revolver': {
    name: 'Revoler',
    sections: [
      'default'
    ]
  },
  '_slotvision': {
    name: 'Slotvision',
    sections: []
  },
  '_flatdog': {
    name: 'Flatdog',
    sections: []
  },
  '_xplosive': {
    name: 'Xplosive',
    sections: [
      'default'
    ]
  },
  '_betsoft': {
    name: 'Betsoft',
    sections: [
      'default',
      'virtualsports'
    ]
  },
  '_kiron': {
    name: 'Kiron',
    sections: [
      'virtualsports'
    ]
  },
  '_quickspin': {
    name: 'QuickSpin',
    sections: [
      'default'
    ]
  },
  '_fugaso': {
    name: 'Fugaso',
    sections: [
      'default'
    ]
  },
  '_habanero': {
    name: 'Habanero',
    sections: [
      'default'
    ]
  },
  '_endorphina': {
    name: 'Endorphina',
    sections: [
      'default'
    ]
  },
  '_evoplay': {
    name: 'Evoplay',
    sections: [
      'default'
    ]
  },
  '_tom_horn': {
    name: 'Tom Horn',
    sections: [
      'default'
    ]
  },
  '_nolimit': {
    name: 'Nolimit',
    sections: [
      'default'
    ]
  },
  '_igtech': {
    name: 'Igtech',
    sections: [
      'default'
    ]
  },
  '_reevo': {
    name: 'Reevo',
    sections: [
      'default'
    ]
  },
  '_isoftbet': {
    name: 'Isoftbet',
    sections: [
      'default'
    ]
  },
  '_booming_games': {
    name: 'Booming games',
    sections: [
      'default'
    ]
  },
  '_wazdan': {
    name: 'Wazdan',
    sections: [
      'default'
    ]
  },
  '_1x2_gaming': {
    name: '1x2 gaming',
    sections: [
      'default'
    ]
  },
  '_fazi': {
    name: 'Fazi',
    sections: [
      'default'
    ]
  },
  '_netent_live': {
    name: 'NetEnt Live',
    sections: [
      'default'
    ]
  },
  '_playson': {
    name: 'Red genn',
    sections: [
      'default'
    ]
  },
  '_vivo': {
    name: 'Vivo Livecasino',
    sections: [
      'liveCasino'
    ]
  },
  '_ezugi': {
    name: 'Ezugi',
    sections: [
      'liveCasino'
    ]
  },
  '_asiagaming': {
    name: 'AsiaGaming',
    sections: [
      'liveCasino'
    ]
  },
  '_evolution': {
    name: 'Evolution',
    sections: [
      'liveCasino'
    ]
  },
  '_betgames': {
    name: 'Betgames',
    sections: [
      'liveCasino'
    ]
  },
  '_mascot': {
    name: 'Mascot',
    sections: [
      'default'
    ]
  },
  '_egt': {
    name: 'EGT Interactive',
    sections: [
      'default'
    ]
  },
  '_digitain': {
    name: 'Digitain',
    sections: [
      'sportsbook'
    ]
  },
  '_ct_gaming': {
    name: 'CT gaming',
    sections: [
      'default'
    ]
  },
  '_digitainsportsbook': {
    name: 'Digitain Sportsbook',
    sections: [
      'sportsbook'
    ]
  },
  '_flatdog_games': {
    name: 'Flatdog games',
    sections: [
      'default'
    ]
  },
  '_merkur': {
    name: 'Merkur',
    sections: [
      'default'
    ]
  },
  '_oneTouch': {
    name: 'One Touch',
    sections: [
      'default'
    ]
  },
  '_stakelogic': {
    name: 'Stakelogic',
    sections: [
      'default'
    ]
  },
  '_amigogaming': {
    name: 'Amigo gaming',
    sections: [
      'default'
    ]
  },
  '_espresso': {
    name: 'Espresso',
    sections: [
      'default'
    ]
  },
  '_gamzix': {
    name: 'Gamzix',
    sections: [
      'default'
    ]
  },
  '_mrSlotty': {
    name: 'Mr Slotty',
    sections: [
      'default'
    ]
  },
  '_onegame': {
    name: 'OneGame',
    sections: [
      'default'
    ]
  },
  '_redrake': {
    name: 'Redrake',
    sections: [
      'default'
    ]
  },
  '_synot': {
    name: 'Synot',
    sections: [
      'default'
    ]
  },
  '_wizard_games': {
    name: 'Wizard games',
    sections: [
      'default'
    ]
  },
  '_amusnet': {
    name: 'Amusnet',
    sections: [
      'default'
    ]
  },
  '_casimi': {
    name: 'Casimi',
    sections: [
      'default'
    ]
  },
  '_elbet': {
    name: 'Elbet',
    sections: [
      'default'
    ]
  },
  '_upgaming': {
    name: 'UpGaming',
    sections: [
      'default'
    ]
  },
  '_g_games': {
    name: 'G games',
    sections: [
      'default'
    ]
  },
  '_spribe': {
    name: 'Spribe',
    sections: [
      'default'
    ]
  },
  '_playngo': {
    name: 'Play\'n GO',
    sections: [
      'default'
    ]
  },
  '_onAir': {
    name: 'OnAir',
    sections: [
      'default'
    ]
  },
  '_3OAKs': {
    name: '3OAKs',
    sections: [
      'default'
    ]
  },
  '_games_global': {
    name: 'Games global',
    sections: [
      'default'
    ]
  },
  '_lucky': {
    name: 'Lucky',
    sections: [
      'default'
    ]
  },
  '_red_genn': {
    name: 'Red genn',
    sections: [
      'default'
    ]
  }
};

function sortByName(a, b) {
  const aProvider = gameProviders[a].name.toLowerCase();
  const bProvider = gameProviders[b].name.toLowerCase();

  if (aProvider < bProvider) {
    return -1;
  }

  if (aProvider > bProvider) {
    return 1;
  }

  return 0;
}

const allProviderKeys = Object.keys(gameProviders)
  .filter(p => Array.isArray(gameProviders[p].sections) && gameProviders[p].sections.length > 0)
  .sort(sortByName);

const defaultProviderKeys = Object.keys(gameProviders)
  .filter(p => Array.isArray(gameProviders[p].sections) && gameProviders[p].sections.indexOf('default') > -1)
  .sort(sortByName);

const virtualSportsProviderKeys = Object.keys(gameProviders)
  .filter(p => Array.isArray(gameProviders[p].sections) && gameProviders[p].sections.indexOf('virtualsports') > -1)
  .sort(sortByName);

const gameProviderNames = {};
// const gameProviderOptions = [];

function buildProviderHelpers(additionalProviders = []) {
  const newProviders = [].concat(additionalProviders);

  Object.keys(gameProviders).forEach(key => {
    // gameProviderOptions.push({
    //   label: gameProviders[key].name,
    //   value: key
    // });
    gameProviderNames[key] = gameProviders[key].name;

    if (newProviders.indexOf(key) !== -1) {
      newProviders.splice(newProviders.indexOf(key), 1);
    }
  });

  if (newProviders.length > 0) {
    for (const key of newProviders) {
      gameProviderNames[key] = key.replaceAll('_', ' ').trim();
      gameProviderNames[key] = gameProviderNames[key].replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
      gameProviders[key] = {
        name: gameProviderNames[key],
        sections: [
          'default'
        ]
      };
    }
  }
}

buildProviderHelpers();


exports.buildProviderHelpers = buildProviderHelpers;
exports.gameProviders = gameProviders;
//exports.gameProviderOptions = gameProviderOptions;
exports.gameProviderNames = gameProviderNames;
exports.allProviderKeys = allProviderKeys;
exports.defaultProviderKeys = defaultProviderKeys;
exports.virtualSportsProviderKeys = virtualSportsProviderKeys;